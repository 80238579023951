<script>
export default {
    props: {
        model: null,
        history: Boolean,
        cc: Boolean,
        hideCcLabels: Boolean,
    },
    computed: {
        quantity() {
            return this.model?.quantity
        },
        price() {
            return this.model?.supplierPrice * this.quantity
        },
        mealName() {
            return this.model?.name
        },
        mealOptions() {
            return (
                this.model?.children ||
                this.model?.mealOptions ||
                []
            ).toSorted((a, b) => {
                let posDiff = a.groupPosition - b.groupPosition
                if (posDiff === 0) {
                    posDiff = a.mealOptionPosition - b.mealOptionPosition
                }
                return posDiff
            })
        },
        labelCodeCount() {
            return (this.model?.labelCode || []).length
        },
        labelCodeStr() {
            return this.model?.labelCode?.join(' / ')
        },
    },
}
</script>

<template lang="pug">
.order-item(v-if="model" :class="{'px-2': history}")
    v-row
        v-col.font-weight-bold(cols="1" align-self="baseline") {{ quantity }}x
        v-col(align-self="baseline")
            .font-weight-bold {{ mealName }}
        v-col.text-right(v-if="!cc" cols="2" align-self="baseline") {{ price | currency }}
        v-col(v-else-if="!hideCcLabels" cols="5" align-self="baseline")
            span.font-weight-bold {{ $tc('labels.label_code', labelCodeCount) }}:&nbsp;
            | {{ labelCodeStr }}
    v-row(v-for="(child, index) in mealOptions" :key="`mealChild-${index}`")
        v-col.pt-0(align-self="baseline" offset="1")
            .order-details
                template(v-if="child.mealoptiongroup && !cc") {{ child.mealoptiongroup.name }}
                template(v-else-if="child.group && !cc") {{ child.group }}
                .font-weight-bold
                    template(v-if="child.quantity > 1 || quantity > 1") {{ child.quantity }}x&nbsp;
                    template(v-if="child.mealoption") {{ child.mealoption.name }}
                    template(v-else-if="child.name") {{ child.name }}
                    template(v-if="quantity > 1") &nbsp;{{ $t('orders.each', { quantity: quantity * child.quantity }) }}
        v-col.text-right.pt-0(v-if="!cc" cols="2" align-self="baseline") {{ child.supplierPrice * child.quantity * quantity | currency }}

</template>

<style scoped lang="scss">
.order-item {
    border-top: 1px solid var(--v-g-e8-base);
    .order-details {
        border-left: 1px solid var(--v-g-e8-base);
        padding-left: 15px;
    }
}
</style>
