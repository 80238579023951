import baseFormType from './type/baseFormType'
import configType from './type/configType'
import RestApiCollection from '@/api/RestApiCollection'
import MapperCollection from '@/services/mapper/MapperCollection'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import Notification from '@/services/Notification/Notification'
import router from '@/router/router'
import Store from '@/store/store'
import RestApiType from '@/api/RestApiType'

function getNotification(api, entity) {
    switch (api) {
        case RestApiType.ORDERS:
            return {
                title: 'labels.order',
                text: entity['identifier'],
            }

        case RestApiType.USERS:
            return {
                title: 'labels.user',
                text: entity['firstName'],
            }

        case RestApiType.COMPANIES:
            return {
                title: 'labels.companies',
                text: entity['name'],
            }

        case RestApiType.RESTAURANTS:
            return {
                title: 'labels.restaurant',
                text: entity['name'],
            }

        case RestApiType.PROMOTIONS:
            return {
                title: 'labels.promotion',
                text: entity['name'],
            }

        case RestApiType.TAG_GROUPS:
            return {
                title: 'labels.tag_group',
                text: get(
                    entity,
                    `translations.${
                        Store.getters[configType.getters.CURRENT_LOCALE]
                    }.name`
                ),
            }

        case RestApiType.TAGS:
            return {
                title: 'labels.tags',
                text: get(
                    entity,
                    `translations.${
                        Store.getters[configType.getters.CURRENT_LOCALE]
                    }.name`
                ),
            }

        case RestApiType.RESIDENTIAL_CANTEENS:
            return {
                title: 'menu.residential_canteens',
                text: entity['name'],
            }

        case RestApiType.RESIDENTIAL_CANTEEN_ADDRESSES:
            return {
                title: 'menu.residential_canteen_addresses',
                text: entity['name'],
            }

        case RestApiType.RESIDENTIAL_CANTEEN_RELATIONS:
            return {
                title: 'menu.residential_canteen_relations',
                text: entity['name'],
            }
        case RestApiType.INVOICES:
            return {
                title: 'menu.invoices',
                text: entity['invoiceNumber'],
            }

        case RestApiType.COMPANY_CLOUDCANTEENS:
            return {
                title: 'labels.company_cloudcanteens',
                text: entity['name'],
            }

        case RestApiType.COMPANY_ALLOWANCES:
            return {
                title: 'labels.company_allowances',
                text: entity['name'],
            }

        case RestApiType.COMPANY_ADDRESSES:
            return {
                title: 'labels.company_addresses',
                text: entity['name'],
            }

        case RestApiType.COMPANY_TEAMS:
            return {
                title: 'labels.company_teams',
                text: entity['name'],
            }

        case RestApiType.REGISTRATION_CODES:
            return {
                title: 'labels.registration_codes',
                text: entity['code'],
            }

        default:
            return {
                title: 'labels.type',
                text: entity?.['name'] || 'unknown',
            }
    }
}

export default {
    namespaced: true,
    state: {
        model: {},
        busy: false,
        hasFormErrors: false,
        formErrors: [],
        savedData: {},
        validator: null,
        errors: null,
    },
    getters: {
        [baseFormType.getters.MODEL](state) {
            return state.model
        },
        [baseFormType.getters.BUSY](state) {
            return state.busy
        },
        [baseFormType.getters.FORM_ERRORS](state) {
            return state.formErrors
        },
        [baseFormType.getters.HAS_FORM_ERRORS](state) {
            return state.hasFormErrors
        },
        [baseFormType.getters.SAVED_DATA](state) {
            return state.savedData
        },
    },
    mutations: {
        [baseFormType.mutations.SET_MODEL](state, payload) {
            state.model = payload
        },
        [baseFormType.mutations.SET_BUSY](state, payload) {
            state.busy = payload
        },
        [baseFormType.mutations.HAS_ERROR](state, payload) {
            state.hasFormErrors = payload
        },
        [baseFormType.mutations.ADD_FORM_ERROR](state, payload) {
            state.formErrors.push(payload)
        },
        [baseFormType.mutations.RESET_FORM_ERRORS](state) {
            state.formErrors = []
        },
        [baseFormType.mutations.SET_BACKUP](state, payload) {
            state.savedData = payload
        },
        [baseFormType.mutations.SET_VALIDATOR](state, payload) {
            state.validator = payload.validator
            state.errors = payload.errors
        },
    },
    actions: {
        [baseFormType.actions.SAVE](
            { commit, state },
            { model, api, id, mapper }
        ) {
            commit(baseFormType.mutations.SET_BUSY, true)
            return new Promise((resolve, reject) => {
                try {
                    const entity = MapperCollection.get(mapper).toEntity(model)
                    commit(baseFormType.mutations.RESET_FORM_ERRORS)
                    commit(baseFormType.mutations.HAS_ERROR, false)
                    let response
                    if (id)
                        response = RestApiCollection.get(api).update(entity, id)
                    else response = RestApiCollection.get(api).create(entity)

                    response
                        .then((response) => {
                            const entity = MapperCollection.get(
                                mapper
                            ).fromEntity(response)
                            commit(
                                baseFormType.mutations.SET_BACKUP,
                                cloneDeep(entity)
                            )
                            commit(
                                baseFormType.mutations.SET_MODEL,
                                cloneDeep(entity)
                            )
                            let notification = getNotification(api, entity)
                            if (id) {
                                Notification.entityUpdated(
                                    notification.title,
                                    notification.text
                                )
                            } else {
                                Notification.entityCreated(
                                    notification.title,
                                    notification.text
                                )
                            }

                            return resolve(entity)
                        })
                        .catch((reason) => {
                            if (reason?.response?.status === 404) {
                                router.push({ name: 'not-found' })
                                return reject(reason.response.status)
                            }

                            if (reason?.response?.status !== 400)
                                return reject(reason?.response?.status)

                            if (reason?.response?.data?.violations) {
                                reason.response.data.violations.forEach(
                                    (violation) => {
                                        let hasViolation = state.validator.fields.find(
                                            {
                                                name: violation.propertyPath,
                                            }
                                        )
                                        if (hasViolation) {
                                            state.errors.add({
                                                field: violation.propertyPath,
                                                msg: violation.message,
                                            })
                                        } else {
                                            commit(
                                                baseFormType.mutations
                                                    .HAS_ERROR,
                                                true
                                            )
                                            commit(
                                                baseFormType.mutations
                                                    .ADD_FORM_ERROR,
                                                `${violation.propertyPath}: ${violation.message}`
                                            )
                                        }
                                    }
                                )
                            } else {
                                commit(baseFormType.mutations.HAS_ERROR, true)
                                commit(
                                    baseFormType.mutations.ADD_FORM_ERROR,
                                    reason?.response?.data?.message ||
                                        'Server error'
                                )
                            }
                            Notification.formError()
                            return reject(reason)
                        })
                        .finally(() => {
                            commit(baseFormType.mutations.SET_BUSY, false)
                        })
                } catch (e) {
                    commit(baseFormType.mutations.SET_BUSY, false)
                    return reject(e)
                }
            })
        },
        [baseFormType.actions.LOAD]({ commit }, { api, id, mapper }) {
            commit(baseFormType.mutations.SET_BUSY, true)
            return new Promise((resolve, reject) => {
                try {
                    RestApiCollection.get(api)
                        .get(id)
                        .then((data) => {
                            const entity = MapperCollection.get(
                                mapper
                            ).fromEntity(data)
                            commit(
                                baseFormType.mutations.SET_BACKUP,
                                cloneDeep(entity)
                            )
                            commit(
                                baseFormType.mutations.SET_MODEL,
                                cloneDeep(entity)
                            )
                            return resolve(entity)
                        })
                        .catch((reason) => {
                            if (reason?.response?.status === 404) {
                                router.push({ name: 'not-found' })
                                return reject(reason?.response?.status)
                            }

                            commit(baseFormType.mutations.HAS_ERROR, true)
                            commit(
                                baseFormType.mutations.ADD_FORM_ERROR,
                                reason?.response?.data?.message ||
                                    'Server error'
                            )
                            return reject(reason)
                        })
                        .finally(() => {
                            commit(baseFormType.mutations.SET_BUSY, false)
                        })
                } catch (e) {
                    commit(baseFormType.mutations.SET_BUSY, false)
                    return reject(e)
                }
            })
        },
        [baseFormType.actions.DELETE]({ commit }, { api, entity }) {
            commit(baseFormType.mutations.SET_BUSY, true)
            return new Promise((resolve, reject) => {
                try {
                    RestApiCollection.get(api)
                        .delete(entity.id)
                        .then(() => {
                            let notification = getNotification(api, entity)
                            Notification.entityDeleted(
                                notification.title,
                                notification.text
                            )
                            return resolve()
                        })
                        .catch((reason) => {
                            commit(baseFormType.mutations.HAS_ERROR, true)
                            commit(
                                baseFormType.mutations.ADD_FORM_ERROR,
                                reason?.response?.data?.message ||
                                    'Server error'
                            )
                            return reject(reason)
                        })
                        .finally(() => {
                            commit(baseFormType.mutations.SET_BUSY, false)
                        })
                } catch (e) {
                    commit(baseFormType.mutations.SET_BUSY, false)
                    return reject(e)
                }
            })
        },
    },
}
