import cloneDeep from 'lodash/cloneDeep'

class CustomExportFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        return formData
    }
}

export default new CustomExportFormMapper()
