import configType from './type/restaurantType'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import cloneDeep from 'lodash/cloneDeep'
import IdFromIri from '@/services/IdFromIri'

export default {
    state: {
        deliverySettings: null,
        restaurant: null,
        activeRestaurant: null,
        restaurantId: null,
        managedRestaurants: {},
    },
    getters: {
        [configType.getters.DELIVERY_SETTINGS](state) {
            return state.deliverySettings
        },
        [configType.getters.RESTAURANT](state) {
            return state.restaurant
        },
        [configType.getters.ACTIVE_RESTAURANT](state) {
            return state.activeRestaurant
        },
        [configType.getters.RESTAURANT_ID](state) {
            return state.restaurantId
        },
        [configType.getters.MANAGED_RESTAURANT](state) {
            return (id) => state.managedRestaurants[id]
        },
    },
    mutations: {
        [configType.mutations.SET_DELIVERY_SETTINGS](state, payload) {
            state.deliverySettings = payload
        },
        [configType.mutations.SET_RESTAURANT](state, payload) {
            state.restaurant = payload
        },
        [configType.mutations.SET_ACTIVE_RESTAURANT](state, payload) {
            state.activeRestaurant = payload
        },
        [configType.mutations.SET_ACTIVE_RESTAURANT_ID](state, payload) {
            state.restaurantId = payload
        },
        [configType.mutations.ADD_MANAGED_RESTAURANT](state, payload) {
            let id = payload?.id
            if (id) {
                state.managedRestaurants[id] = cloneDeep(payload)
            }
        },
    },
    actions: {
        [configType.actions.SET_ACTIVE_RESTAURANT_ID]({ commit }, payload) {
            return new Promise((resolve) => {
                let id = IdFromIri.getId(payload)
                commit(configType.mutations.SET_ACTIVE_RESTAURANT_ID, id)
                resolve(id)
            })
        },
        [configType.actions.GET_RESTAURANT]({ commit }, id) {
            return new Promise((resolve, reject) => {
                try {
                    RestaurantsApiClient.get(id)
                        .then((data) => {
                            const entity = MapperCollection.get(
                                MapperType.RESTAURANT_FORM
                            ).fromEntity(data)

                            commit(
                                configType.mutations.SET_RESTAURANT,
                                cloneDeep(entity)
                            )
                        })
                        .catch((reason) => {
                            return reject(reason)
                        })
                } catch (e) {
                    return reject(e)
                }
            })
        },
        async [configType.actions.LOAD_MANAGED_RESTAURANTS](
            { commit, dispatch, getters },
            managedRestaurants
        ) {
            if (!managedRestaurants?.length) return

            for (let i in managedRestaurants) {
                let id = IdFromIri.getId(managedRestaurants[i])

                let restaurant = await RestaurantsApiClient.get(id)
                commit(configType.mutations.ADD_MANAGED_RESTAURANT, restaurant)
            }

            await dispatch(
                configType.actions.SET_ACTIVE_RESTAURANT_ID,
                managedRestaurants[0]
            )
            commit(
                configType.mutations.SET_ACTIVE_RESTAURANT,
                getters[configType.getters.MANAGED_RESTAURANT](
                    IdFromIri.getId(managedRestaurants[0])
                )
            )
        },
    },
}
